<template>
  <div>

    <!-- #region::Table -->
    <vue-good-table
      ref="pieces-table"
      class="vgt-table-checkbox-styled"
      :columns="columns"
      :rows="pieces"
      style-class="vgt-table condensed vgt-checkbox-col"
      :line-numbers="true"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdown: [3, 5, 10],
        dropdownAllowAll: true,
        jumpFirstOrLast : true,
        firstLabel : 'Primera página',
        lastLabel : 'Última página',
        nextLabel: 'Siguiente',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Piezas por página',
        ofLabel: 'de',
        allLabel: 'Todo',
      }"
    >

      <!-- #region::Empty response -->
      <div slot="emptystate">
        This will show up when there are no rows
      </div>
      <!-- #endregion::Empty response -->

      <!-- #region::Column headers -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          <h6 class="capitalize-col-hd"><strong>{{ props.column.label }}</strong></h6>
        </span>
      </template>
      <!-- #endregion::Column headers -->

      <!-- #region::Modifying rows cells -->
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- #region::Availability cell -->
        <span v-if="props.column.field === 'Tag'">
          <div class="d-flex justify-content-start">
            <h6>{{ props.row.Tag }}</h6>
            <div
              v-if="canShowUnavailableTag(props.row)"
              class="sm-banner-alert alert-gray"
              role="alert"
            >
              No disponible
            </div>
            <div
              v-else-if="canShowSoldOutTag(props.row)"
              class="sm-banner-alert alert-yellow"
              role="alert"
            >
              Vendida
            </div>
            <div
              v-else-if="canShowTransferTag(props.row)"
              class="sm-banner-alert alert-pink"
              role="alert"
            >
              Traspaso
            </div>
          </div>
        </span>
        <!-- #endregion::Availability cell -->

        <!-- #region::Availability cell -->
        <span v-else-if="props.column.field === 'Availability'">
          <h6><b-badge
            class="ava-label"
            :variant="getAvailabilityStyle(props.row.Availability)"
          >
            {{ props.row.Availability }}
          </b-badge></h6>
        </span>
        <!-- #endregion::Availability cell -->

        <!-- #region::Arrive date cell -->
        <span v-else-if="props.column.field === 'ArriveDate'">
          <h6 class="ava-label">{{ props.row.ArriveDate | moment("DD/MM/YYYY") }}</h6>
        </span>
        <!-- #endregion::Arrive date cell -->

        <!-- #region::Actions column -->
        <span v-else-if="props.column.field === 'actions'">

          <!-- #region::Delete circuit button -->
          <b-button
            v-b-tooltip.hover.top="'Eliminar'"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="deletePiece(props.row)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <!-- #endregion::Delete circuit button -->

        </span>
        <!-- #endregion::Actions column -->

        <!-- #region::Rest of columns -->
        <span v-else>
          <h6>{{ props.formattedRow[props.column.field] }}</h6>
        </span>
        <!-- #endregion::Rest of columns -->

      </template>
      <!-- #endregion::Modifying rows cells -->

    </vue-good-table>
    <!-- #endregion::Table -->

  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { BBadge, BButton, VBTooltip } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
// #endregion

/**
 * Rutas para la comprobación de estados de piezas
 */
const ROUTES = ['quote-details', 'update-quote']

export default {
  components: {
    BBadge,
    BButton,
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    pieces: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    // * Conditional columns
    showInvoiceColumn: {
      type: Boolean,
      default: false,
    },
    showActionsButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'Tag',
          label: 'Tag',
          hidden: !this.$ability.can('read', 'Piece_tag'),
        },
        {
          field: 'NoSeries',
          label: 'No. serie',
          hidden: !this.$ability.can('read', 'Piece_serie'),
        },
        {
          field: 'NoPetition',
          label: 'No. Pedimento',
          hidden: !this.$ability.can('read', 'Piece_petition'),
        },
        {
          field: 'NoInvoice',
          label: 'No. factura',
          hidden: !this.showInvoiceColumn
            || !this.$ability.can('read', 'Piece_invoice'),
        },
        {
          field: 'NoImport',
          label: 'No. importación',
          hidden: !this.$ability.can('read', 'Piece_import'),
        },
        {
          field: 'Condition',
          label: 'Condición',
          hidden: !this.$ability.can('read', 'Piece_condition'),
        },
        {
          field: 'Availability',
          label: 'Disponibilidad',
          hidden: !this.$ability.can('read', 'Piece_availability'),
        },
        {
          field: 'Location',
          label: 'Ubicación',
          hidden: !this.$ability.can('read', 'Piece_location'),
        },
        {
          field: 'ArriveDate',
          label: 'Fecha de llegada',
          hidden: !this.$ability.can('read', 'Piece_arrival_date'),
        },
        {
          field: 'actions',
          label: 'Acciones',
          hidden: !this.showActionsButton,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getQuoteProducts: 'quotes/getQuoteProducts',
    }),
    quoteProducts: {
      get() { return this.getQuoteProducts },
    },
    quoteProduct() {
      return this.quoteProducts.find(quoteProduct => quoteProduct.IdProduct === this.product.IdProduct)
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('rendering', false)
    })
  },
  methods: {
    ...mapActions({
      updateQuoteProduct: 'quotes/updateQuoteProduct',
    }),
    getAvailabilityStyle(availability) {
      switch (availability) {
        case 'Disponible':
          return 'light-success'
        case 'No disponible':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },
    statusBorderStyle(status) {
      switch (status) {
        case 'Orden':
          return 'sm-banner-alert-bordered'
        default:
          return 'sm-banner-alert'
      }
    },
    statusColorStyle(status) {
      switch (status) {
        case 'Enviado':
          return 'alert-yellow'
        case 'Disponible':
          return 'alert-green'
        case 'Orden':
          return 'alert-green-white'
        case 'No disponible':
          return 'alert-orange'
        case 'Cancelado':
          return 'alert-gray'
        default:
          return 'alert-blue'
      }
    },
    deletePiece(piece) {
      if (this.quoteProduct) {
        const filteredPieces = this.quoteProduct.pieces.filter(
          quoteProductPiece => quoteProductPiece.IdPiece !== piece.IdPiece,
        )

        this.quoteProduct.pieces = filteredPieces.map(filteredPiece => ({ ...filteredPiece }))
        this.updateQuoteProduct(this.quoteProduct)
      }
    },
    canShowSoldOutTag(piece) {
      return piece.SoldOut === 'Si'
    },
    canShowUnavailableTag(piece) {
      return piece.Status === 0 && ROUTES.includes(this.$route.name)
    },
    canShowTransferTag(piece) {
      return piece.Transfer === 'Si' && ROUTES.includes(this.$route.name)
    },
  },
}
</script>
